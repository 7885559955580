(function (global) {
    'use strict';
    // var appModulesHelperBasePath = global.SiteUrl + '/Scripts/Admin/Helper/';

    require.config({
        baseUrl: '/Scripts/Lib/',
        paths: {
            //tableOrder: appModulesHelperBasePath + 'tableOrder',
        }
    });

    var importGlobals = function () {
        define('jquery', [], function () { return global.jQuery; });
        define('pubsub', [], function () { return global.pubsub; });
        define('viewData', [], function () { return global.viewData; });
    };

    importGlobals();

})(this);

require(['jquery', 'viewData'], function ($, viewData) {
    'use strict';

    // one-view project dropdown - change in value
    $('#OneViewProjectID').change(function () {
        var projectId = parseInt($(this).val());
        $('#All-Search-Results .template-col').addClass('hide');
        $('#All-Search-Results .template-col .record-wrapper').removeClass('selected');
        $('#All-Search-Results .template-col').each(function () {
            if (projectId == parseInt($(this).attr('data-projectid'))) {
                $(this).removeClass('hide');
            }
        });
    });

    $('#Search-Templates').keyup(function () {
        var searchString = $(this).val();
        SearchTemplates(searchString);
    });

    var SearchTemplates = function (searchString) {
        if (searchString.length >= 2) {
            $('#All-Search-Results .template-col').addClass('hide');
            $('#All-Search-Results .template-col .record-wrapper').removeClass('selected');
            var IDs = [];
            for (var i = 0; i < viewData.OneViewTemplates.length; i++) {
                if (viewData.OneViewTemplates[i].Sku.toLowerCase().indexOf(searchString.toLowerCase()) >= 0) {
                    IDs.push(viewData.OneViewTemplates[i].ID);
                }
            }
            $('#All-Search-Results .template-col').each(function () {
                var id = parseInt($(this).attr('data-id'));
                if ($.inArray(id, IDs) !== -1) {
                    $(this).removeClass('hide');
                }
            });
        }
        else {
            return;
        }
    }

    $(document).on('click', '.department-admin-one-view-search-wrapper #All-Search-Results .record-wrapper', function () {
        var $this = $(this);
        if ($this.hasClass('selected')) {
            $this.removeClass('selected');
        } else {
            $this.addClass('selected');
        }
    });


    $('#Submit-BISIBI').click(function () {
        var selectedItems = $('.department-admin-one-view-search-wrapper #All-Search-Results .record-wrapper.selected');
        if (selectedItems.length == 0) {
            alert('At least 1 item needs to be selected');
            return;
        }

        var objPostModel = {
            DepartmentID: $('#DepartmentID').val(),
            OneViewHotspotTemplates: [],
        };

        var validPostModel = true;
        var HotspotTemplateIDs = [];
        selectedItems.each(function () {
            var OneViewHotspotTemplates = {}
            OneViewHotspotTemplates.HotSpotTemplateID = $(this).closest('.template-col').attr('data-id');
            OneViewHotspotTemplates.ProjectID = $(this).closest('.template-col').attr('data-projectid');
            var price = parseFloat($(this).find('div.template-price input').val());
            if (!$.isNumeric(price))
            {
                alert($(this).find('div.template-price input').val() + ' is not a valid price');
                validPostModel = false;;
            }
            OneViewHotspotTemplates.Price = price;

            var sku2 = $(this).find('input#template_SKU2').val();
            if (sku2 != '')
            {
                var price2 = parseFloat($(this).find('div.template-price2 input').val());
                if (!$.isNumeric(price2)) {
                    alert($(this).find('div.template-price2 input').val() + ' is not a valid price');
                    validPostModel = false;;
                }
                OneViewHotspotTemplates.Price2 = price2;
            }
            else {
                OneViewHotspotTemplates.Price2 = 0;
            }

            objPostModel.OneViewHotspotTemplates.push(OneViewHotspotTemplates);
        });

        if (!validPostModel)
            return;

        var postModel = HotspotTemplateIDs = JSON.stringify(objPostModel);
        $('#PostModel').html(postModel);
        parent.postMessage(
        {
            SelectedBuildsJson: JSON.stringify(objPostModel),
        },
            viewData.DepartmentBaseURL + "/Admin/BISIBIOneViewProduct"
        );                  
    });     


    $('.template-price input').keyup(function () {
        var price1 = parseFloat($(this).val());
        var price2 = 0;// parseFloat($(this).closest('.record-wrapper').find('.template-price2 input').val());
        var sku2 = $(this).closest('.record-wrapper').find('input#template_SKU2').val();
        if (sku2 != '')
        {
            price2 = parseFloat($(this).closest('.record-wrapper').find('.template-price2 input').val());
        }
        var finalPrice = price1 + price2;
        if ($.isNumeric(finalPrice)) {
            $(this).closest('.record-wrapper').find('span.department-final-price').html(finalPrice);
        } else {
            $(this).closest('.record-wrapper').find('span.department-final-price').html('--');
        }
    });     
                
    $('.template-price2 input').keyup(function () {
        var price1 = parseFloat($(this).closest('.record-wrapper').find('.template-price input').val());
        var price2 = parseFloat($(this).val());
        var finalPrice = price1 + price2;
        if ($.isNumeric(finalPrice))
        {
            $(this).closest('.record-wrapper').find('span.department-final-price').html(finalPrice);
        } else {
            $(this).closest('.record-wrapper').find('span.department-final-price').html('--');
        }
    });

});         